@import 'variables';

html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

body {
  line-height: 1;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

input, select {
  vertical-align: middle;
}

body {
  font-family: $font-primary !important;
  color: #222;
  font-size: 14px;
  font-weight: 500;
  background: #F6F5F8;
}

body, p {
  line-height: 1.4;
}

small {
  font-size: 0.875rem !important;
  font-family: $font-primary !important;
}

.MuiTypography-h1,
h1 {
  font-family: $font-primary !important;
  font-size: 1.5rem !important;
  font-weight: 500 !important;
}

.MuiTypography-h2,
h2 {
  font-family: $font-primary !important;
  font-size: 1.25rem !important;
  font-weight: 500 !important;
}

.MuiTypography-h3,
h3 {
  font-family: $font-primary !important;
  font-size: 1rem !important;
  font-weight: 500 !important;
}

strong, b {
  font-weight: bold;
}

.height-100-vh {
  height: 100vh;
}

.placeholder-image {
  height: 125px;
  width: 100%;
  text-align: center;
  line-height: 140px;
  background: #e9e9e9;

  svg {
    opacity: 0.2;
  }
}

.container > .MuiGrid-container {
  max-width: 100%;
  overflow: hidden;
}

.hidden {
  display: none;
}

.sidebar {
  position: relative;
  z-index: 99;
  box-sizing: border-box;

  .MuiPaper-root {
    border-radius: 0;
  }
}

#purchase-bar {
  position: relative;
  min-height: 100vh;
  font-size: 14px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100vh;

  > header.action-bar {
    flex: 0 0 115px;
  }

  .MuiToolbar-root {
    > .MuiGrid-root {
      height: 68px;

      .MuiButton-outlined {
        height: 100%;
        border: 0;
        border-right: 1px solid #ccc;
        border-left: 1px solid #ccc;
        border-radius: 0;
        margin-right: -1px;

        &.paid {
          background: #21c855;
          color: #FFF;
        }

        &.receipt-button {
          padding: 0 0.25rem;

          svg {
            fill: #222;
          }
        }
      }
    }
  }

  .product-price {
    font-weight: 600;
    color: #222;
  }

  .MuiListItem-root {
    color: #666;

    strong {
      color: #101010;
    }
  }

  .MuiListSubheader-root {
    color: #333;
    font-size: 1.25rem;
  }

  .list-header {
    font-weight: 600;
    color: #666;
    text-transform: uppercase;
    font-size: 12px;
  }
}

#payment-buttons {
  position: inherit;
  flex: 0;
  top: auto;

  .MuiToolbar-dense {
    text-align: center;
    padding: 0;
  }

  button {
    flex: 1;
    border: 1px solid rgb(196, 187, 162) !important;
    border-radius: 0;
    padding: 1.75rem 1rem;
    box-shadow: none;
    margin-right: -1px;
    margin-top: -1px;
  }

  button.giftcard {
    margin-top: 2rem;
    padding: 1rem;
  }
}

.MuiMenu-list {
  .MuiSvgIcon-root {
    max-width: 20px;
    margin-right: 1rem;
    opacity: 0.4;
  }

  .MuiMenuItem-root {
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
    font-size: 1rem;
    font-weight: 500;
  }
}

.MuiTab-root {
  text-transform: capitalize !important;
}

.MuiPaper-elevation1 {
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0px 1px 3px 0px rgba(0, 0, 0, 0.08) !important;
}

.product-card {
  h3 {
    font-weight: 500;
    margin: 0.25rem 0;
  }
}

.search-bar {
  background-color: white !important;
}

.purchase-list {
  flex: 1;
  min-height: min-content;
  overflow-y: scroll;
  position: relative;

  .purchase-total-sum {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: #FFF;
  }

  .purchase-list-items {
    flex: 1;
  }

  .MuiListSubheader-root {
    background: #FFF;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  }
}

#purchase-total-sum {
  background: #f9f9f9;
  box-shadow: 0 -1px 1px rgba(0, 0, 0, 0.1);

  .total-row {
    font-size: 14px;

    &.total-sum {
      font-size: 16px;
    }
  }
}

.btn {
  &.btn-danger {
    background: #ff5055;
    color: #FFF;
  }
}

.purchases {
  max-width: inherit;
  overflow: auto;
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  min-height: 59px;

  > * {
    flex: 0 0 100px;
    min-height: 48px;
    border-bottom: 1px solid #ccc !important;

    &.active {
      background: $color-primary;
      color: #FFF;

      &:hover {
        background: #555;
      }
    }

  }
}

.MuiChip-root.chip-success {
  background: #198754;
}

.MuiChip-root.chip-danger {
  background: #ff5055;
}

.machine-settings-button-wrapper,
.receipt-button-wrapper {
  button {
    border-bottom: 1px solid #ccc !important;
    padding: 5px 32px;
  }
}

.add-new-purchase {
  position: absolute !important;
  bottom: 100px;
  right: 2rem;
}

.edit-row-count {
  button {
    padding: 12px 18px;
  }
}

.Toastify .Toastify__toast {
  min-height: 36px;
  font-family: $font-primary;
  font-weight: 400;

  &.Toastify__toast--success {
    background: #F2FAF7;
    color: #0B5944;
    border: 1px solid #e4fff8;
  }

  &.Toastify__toast--warning {
    background: #FDFDEA;
    color: #77421C;
    border: 1px solid #f3e3c3;
  }

  &.Toastify__toast--error {
    background: #FDF2F2;
    color: #9A1C1C;
    border: 1px solid #ffdada;
  }
}

.flex-child-separation > * {
  margin: 0 0.1rem;
}

.status-bar {
  background: #999;
  color: #FFF;
  text-align: center;
  padding: 0.25rem 0;

  &.status-success {
    background: #198754;
  }

  &.status-error {
    background: #ff5055;
  }
}

.grey-1 {
  color: #666;
}

.no-border .MuiOutlinedInput-notchedOutline {
  border-color: transparent !important;
}

.receipt-wrapper,
.receipt-wrapper h3,
.receipt-wrapper .MuiTypography-body1 {
  font-family: "Monaco", monospaced, sans-serif !important;
  font-weight: 400 !important;
  font-size: 20px !important;
}

.receipt-wrapper h3 {
  font-weight: 700 !important;
}

.product-card-container.color-white {
  h2, h3, p, small {
    color: white !important;
  }
}

.category-tabs {
  min-height: 80px;

  .MuiTab-root {
    min-height: 80px;
    font-size: 1rem;
  }
}

.confirm-dialog-actions {
  button {
    margin-left: 2rem;
    padding: 12px 24px;
    min-width: 125px;
  }
}

#login {
  width: 100%;
  height: auto;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f5f5f5;

  .container {
    max-width: 420px;
    text-align: center;
    background: #FFF;
    box-shadow: 0 1px 2px rgba(0,0,0,0.1), 0 2px 8px rgba(0,0,0,0.06);
    padding: 2rem;
    border-radius: 8px;

    h2 {
      margin-bottom: 1rem;
    }
  }

  .form-field {
    margin: 0.5rem 0;
  }

  .sign-in {
    margin-top: 1rem;
  }
}